.git-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  height: 100vh;
  position: fixed;
  overflow: hidden;

  .form-modal-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .git-form-container {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 685px;
      width: 100%;
      background-color: #fff;
      padding: 40px 50px;
      max-height: 100%;
      overflow-y: auto;
      gap: 20px;
      border-radius: var(--border-radius-default);

      .git-form-title {
        font-size: 22px;
        font-weight: bold;
      }

      .git-form-subtitle {
        font-weight: 18px;
      }

      form {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 28px;

        .form-notes-container {
          font-size: 18px;
          color: #000;
          text-align: start;

          .required {
            color: var(--red);
          }
        }

        .form-notification {
          width: 100%;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: var(--primary);

          &.success {
            color: var(--success-color);
          }

          &.error {
            color: var(--red);
          }
        }
      }

      .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(255 255 255 / 90%);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

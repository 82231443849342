.page-faq-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 848px;
  margin-right: auto;
  gap: 24px;

  h1 {
    color: #161616;
    font-size: 32px;
    text-align: start;
  }

  .text-container {
    color: #161616;
    text-align: start;
    font-size: 16px;
    width: 100%;

    ol {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;

      li {
        width: 100%;
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: #161616;
    }

    h3 {
      margin-bottom: 12px;
    }

    p {
      line-height: 1.5em;
    }
  }
}

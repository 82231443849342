.videos-section {
  display: flex;
  max-width: 1530px;
  align-items: center;
  flex-direction: column;
  margin: 20px 50px;
  gap: 20px;
  width: 100%;

  .videos-title,
  .back-link {
    display: flex;
    width: 100%;
    text-align: left;
    font-size: 26px;
    font-weight: bold;
  }

  .back-link {
    & > .link {
      color: #cfcfcf;
      font-size: 16px;
    }
  }

  .video-container {
    display: grid;
    width: 100%;
    align-items: start;
    column-gap: 12px;
    grid-template-columns: repeat(4, minmax(0px, 1fr));

    .column-container {
      display: grid;
      row-gap: 12px;
      grid-template-columns: minmax(0px, 1fr);
    }
  }
}

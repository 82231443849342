.buy-now-container {
  position: relative;
  justify-content: center;
  display: flex;
  flex: 1;
  gap: 54px;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .thumbnail {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.vertical {
      display: flex;
      min-width: 320px;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      & > video {
        width: 50%;
        height: auto;
      }
    }

    & > video {
      width: 100%;
      height: auto;
      background-repeat: no-repeat;
    }
  }

  .video-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 476px;
    gap: 12px;

    .video-title {
      display: inline-flex;
      flex-direction: column;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .video-keywords {
      display: flex;
      flex-direction: column;
      color: #cfcfcf;
      .video-keywords-title {
        color: #cfcfcf;
      }

      .video-keywords-text {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }

    &.vertical {
      display: flex;
      flex-direction: column;
    }

    .buy-vid-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .buy-action {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 20px;

      .video-meta-container {
        display: flex;
        flex-direction: column;

        .video-meta {
          display: flex;
          gap: 10px;
        }
      }

      .price-container {
        display: flex;
        border-top: 1px solid #cfcfcf;
        flex-direction: column;
        gap: 12px;

        .notification-text {
          width: 100%;
          text-align: start;
          color: var(--primary);
          font-size: 18px;
        }

        .resolution-type {
          display: flex;
          width: 100%;
          flex-direction: column;
          border: none;
          gap: 10px;
          padding: 0;
          margin-top: 10px;
        }

        .radio {
          display: flex;
          width: 100%;
          justify-content: space-between;

          .res-info {
            cursor: pointer;

            label,
            input {
              cursor: pointer;
            }
          }
        }
      }

      .buy-now-btn {
        svg {
          width: 22px;
          height: 22px;
          min-width: 22px;
        }
      }

      .legend {
        text-align: center;
      }
    }
  }
}

.download-now-container {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  gap: 54px;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .thumbnail {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-height: 504px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.vertical {
      display: flex;
      min-width: 320px;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      & > video {
        width: 50%;
        height: auto;
      }
    }

    & > video {
      width: 100%;
      height: auto;
      background-repeat: no-repeat;
    }
  }

  .download-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 476px;
    position: relative;
    gap: 40px;

    .download-now-btn {
      width: 100%;
    }

    .buy-vid-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .buy-action {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 20px;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    align-items: center;
    justify-content: center;
    background-color: rgb(255 255 255 / 94%);

    img {
      width: 260px;
    }

    p {
      text-align: center;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;

    .email-notification {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: var(--primary);

      &.success {
        color: var(--success-color);
      }

      &.error {
        color: var(--red);
      }
    }
  }
}

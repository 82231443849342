.page-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  min-height: unset;

  .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    h1 {
      font-size: 24px;
      text-align: center;
      color: var(--success-color);
    }

    p {
      font-size: 18px;
      text-align: center;
      max-width: 520px;
      width: 100%;
      line-height: 24px;
    }
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #d9d9d9;

  header {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;

    .logo {
      cursor: pointer;
    }

    .left-header {
      display: flex;
      height: 100%;
      align-items: center;
      gap: 120px;
    }

    .alt-nav {
      display: flex;
      justify-content: space-between;
      gap: 40px;
    }

    nav {
      display: flex;
      height: 100%;
      gap: 50px;
    }

    .pending-nav-link,
    .active-nav-link,
    .nav-link {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #000;
      text-decoration: none;
      padding: 20px 0;
      cursor: pointer;
    }

    .popup-link {
      display: flex;
      flex: 1;
    }

    .popup-link {
      display: flex;
    }

    .link {
      display: inline-flex;
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .faq {
      font-size: 20px;
    }

    .active-nav-link {
      font-weight: bold;
      border-bottom: 3px solid #000;
    }
  }
}

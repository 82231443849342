.common-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 64px;
  padding: 12px 24px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border-radius: var(--border-radius-default);
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all ease-in-out 0.2s;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 28px;
      height: 28px;
      min-width: 28px;
    }
  }

  &.primary {
    background-color: var(--color-background-primary-button);

    &:hover {
      background-color: var(--color-background-primary-button-hover);
    }
  }

  &.secondary {
    background-color: var(--color-background-secondary-button);

    &:hover {
      background-color: var(--color-background-secondary-button-hover);
    }
  }

  &.dark {
    background-color: #000;

    &:hover {
      background-color: #272729;
    }
  }
}

.video-thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 187px; // Устанавливаем минимальную высоту, чтобы избежать схлопывания
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; // Меняем contain на cover, чтобы заняло всю область
    transition: opacity 0.3s ease-in-out;
  }

  .skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  &:hover {
    .button-buy {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear 0s;
    }
  }

  .hidden {
    display: none;
  }

  .list-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .button-buy {
    opacity: 0;
    visibility: hidden;
    right: 22px;
    bottom: 26px;
    position: absolute;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 61px;
    height: 56px;
    min-width: 61px;
  }
}

:root {
  --primary: #2483ff;
  --secondary: #ff9500;
  --blue-1: #1f73e0;
  --yellow-1: #e28707;
  --red: #ff3b30;
  --green: #28a745;

  --border-radius-default: 4px;
  --success-color: var(--green);

  // Button
  --color-background-primary-button: var(--primary);
  --color-background-primary-button-hover: var(--blue-1);
  --color-background-secondary-button: var(--secondary);
  --color-background-secondary-button-hover: var(--yellow-1);

  --colo-text-link-hover: var(--blue-1);
}

.common-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;

  label {
    font-size: 20px;
    font-weight: bold;

    &:has(.note-exist) {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .note {
        font-weight: 500;
      }
    }

    .required-mark {
      font-weight: bold;
      color: var(--red);
    }
  }

  input {
    border: 1px solid #000;
    width: 100%;
    border-radius: var(--border-radius-default);
    padding: 12px 20px;
    min-height: 58px;
    font-size: 18px;
    color: #000;
  }
}

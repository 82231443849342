.about-section {
  display: flex;
  width: 100%;
  background-color: #f3f4f5;
  justify-content: center;
  padding: 20px 0;
  margin: 30px 0;
  flex-direction: column;
  gap: 44px;

  .about-sub-section {
    display: flex;
    max-width: 1530px;
    gap: 50px;

    .about-text {
      display: flex;
      flex-direction: column;

      .about-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 12px;
      }
    }

    .get-in-touch {
      display: flex;
      flex-direction: column;

      .git-illustration {
        width: 509px;
        height: 285px;
        background-image: url("../../images/get_in_touch.png");
        background-size: cover;
        background-repeat: no-repeat;
      }

      .git-bottom {
        display: flex;
        margin: 20px 0 0;
        justify-content: space-between;
      }

      .get-in-touch-btn {
        min-width: 292px;
      }

      .git-pricing {
        display: flex;
        flex-direction: column;

        .git-price {
          font-size: 24px;
          font-weight: bold;
          text-align: right;
        }

        .git-price-details {
          font-size: 15px;
          text-align: right;
        }
      }
    }
  }

  .footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    a {
      cursor: pointer;
      color: #000000;
      text-decoration: none;
      transition: all ease-in-out 0.2s;
      font-size: 16px;

      &:hover {
        color: var(--colo-text-link-hover);
      }

      &:active {
        color: #000000;
      }
    }
  }
}

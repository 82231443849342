/* Skeleton.module.scss */
.skeleton {
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 25%,
    rgba(230, 230, 230, 1) 50%,
    rgba(240, 240, 240, 1) 75%
  );
  background-size: 300% 100%;
  animation: skeleton-loading 4.5s ease-in-out infinite;
  border-radius: 4px;

  &.skeleton-rect {
    border-radius: 4px;
  }

  &.skeleton-circle {
    border-radius: 50%;
  }
}

@keyframes skeleton-loading {
  from {
    background-position: 300% 0;
  }
  to {
    background-position: -300% 0;
  }
}

@font-face {
  font-family: "Helvetica";
  src: url("helvetica/Helvetica.ttf");
}
@font-face {
  font-family: "Helvetica";
  src: url("helvetica/Helvetica-Bold.ttf");
  font-weight: bold;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica", sans-serif;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
figure,
hr {
  margin: 0;
}

/** text colors */

.blue {
  color: #007aff !important;
}

/** font weight */
.bold {
  font-weight: bold;
}

.page-wrapper {
  text-align: start;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 1530px;
  padding: 20px 24px;
}
